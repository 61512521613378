import baseURL from "@/utils/config";
import request from "@/plugins/axios"
//优惠卷
export function getFindCanGetCoupon(data,langEnv=2){
  return request({
      url:`${baseURL.api}/api/product/findCanGetCoupon`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}
//领取优惠卷
export function getTakeCanGetCoupon(data,langEnv=2){
  return request({
      url:`${baseURL.api}/authapi/user/takeCoupon`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}
//我的优惠卷
export function getSearchUserCoupon(data,langEnv=2){
  return request({
      url:`${baseURL.api}/authapi/user/searchUserCoupon`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}