<template>
  <div class="getCoupons">
    <nav-bar
      :isFixed="true"
      :title="$t('領券中心')"
      :bgc="'background-color: #90d12e;'"
      v-if="!isMobileTerminal()"
    ></nav-bar>
    <div
      class="money-content"
      v-for="(item, k) in CanGetCoupon.canGetDisCoupons"
      :key="k"
    >
      <div class="title">
        <!-- <img src="@/assets/image/coupon/title_limited@2x.png" alt="" /> -->
      </div>
      <div class="content">
        <div class="top" v-if="item.type === '1'">
          <!-- <div class="left" style="color: #bdbdbd; font-weight: 400">
            {{
              `${item.month}月${item.day}日 ${item.hours}:${item.minutes}:${item.seconds}`
            }}
          </div>
          <div class="right">
            <div style="color: #bdbdbd">即将开始</div>
          </div> -->
        </div>
        <div class="top" v-else>
          <!-- <div class="left">
            <span> 距离结束 </span>
            <div
              v-if="countdownValue[k] && countdownValue[k][1] != 0"
              class="d block-box"
            >
              {{ countdownValue[k][0] }}{{ countdownValue[k][1] }}
            </div>
            <span>:</span>
            <div v-if="countdownValue[k]" class="h block-box">
              {{ countdownValue[k][2] }}{{ countdownValue[k][3] }}
            </div>
            <span>:</span>
            <div v-if="countdownValue[k]" class="m block-box">
              {{ countdownValue[k][4] }}{{ countdownValue[k][5] }}
            </div>
            <span>:</span>
            <div v-if="countdownValue[k]" class="s block-box">
              {{ countdownValue[k][6] }}{{ countdownValue[k][7] }}
            </div>
          </div> -->
          <div class="right">
            <!-- <img src="@/assets/image/coupon/ic_hot.png" alt="" />
            <div class="right-content" text="正在疯抢">正在疯抢</div> -->
          </div>
        </div>
        <div class="bottom" v-mouse-drag-scroll>
          <div class="noquantity-content">
            <div class="limit-content" v-for="i in item.coupons" :key="i.id">
              <div class="noquantity-received-img">
                <img
                  v-if="item.isGet && $route.query.langEnv == 3"
                  src="@/assets/image/coupon/Usedzh.png"
                  alt=""
                />
                <img
                  v-if="item.isGet && $route.query.langEnv != 3"
                  src="@/assets/image/coupon/Receive.png"
                  alt=""
                />
              </div>

              <!-- <img src="@/assets/image/coupon/money_bg.png" alt="" /> -->
              <div class="limit-left">
                <div class="limit-price">
                  <div class="price" v-if="$route.query.langEnv == '3'">
                    {{ ("" + i.discount)[0]
                    }}<span
                      >{{
                        i.discount % 10 === 0 ? "" : "." + ("" + i.discount)[1]
                      }}折</span
                    >
                  </div>

                  <div class="price" v-else>
                    {{ "" + (100 - i.discount) + "%" }}<span>Discount</span>
                  </div>
                </div>
                <div class="max-conditions">{{ i.tag }}</div>
              </div>
              <div class="limit-right">
                <div>
                  <div class="title">
                    <div class="left">
                      <!-- <div class="left-content">
                    {{ item.isAll ? "全场券" : "品类券" }}
                  </div> -->
                      {{ i.name }}
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="left">{{ i.date }}</div>
                    <div
                      class="receive-btn"
                      :class="`${item.type === '0' ? '' : 'received'}`"
                      @click="nowOrder(i.id, 0, item.type)"
                      v-if="!i.isGet"
                    >
                      {{ $t("領取") }}
                    </div>
                  </div>
                  <div class="question" v-if="i?.isAll">
                    <div v-if="i?.isAll">{{ $t("適用於所有的產品") }}</div>
                  </div>
                  <div class="maxDiscountPrice" v-if="i.maxDiscountPrice">
                    <div v-if="$route.query.langEnv == 3">
                      {{ $t("最高可减") }}{{ i.maxDiscountPrice }}
                    </div>
                    <div v-else>
                      {{ $t("最高") }}{{ i.maxDiscountPrice }}{{ $t("可减") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            class="coupons"
            v-for="(i, index) in item.coupons"
            :key="i.id"
            :class="
              `${index === 0 ? 'first-coupon' : ''} ${
                index === item.length - 1 ? 'last-coupon' : ''
              }`
            "
          >
            <div class="received-img">
              <img
                src="@/assets/image/coupon/received.png"
                alt=""
                v-if="i.isGet"
              />
              <img
                src="@/assets/image/coupon/no-inventory.png"
                v-if="!i.isGet && i.noStock"
                alt=""
              />
            </div>
            <div class="coupons-title">
              <div class="coupons-num">共{{ i.distributionQuantity }}张</div>
              <div class="decoration"></div>
            </div>
            <div class="coupons-content">
              <div>
                <div class="discount">
                  {{ ("" + i.discount)[0]
                  }}<span style="font-size: 14px"
                    >{{
                      i.discount % 10 === 0 ? "" : "." + ("" + i.discount)[1]
                    }}折</span
                  >
                </div>

                <div class="condition">{{ i.tag }}</div>
              </div>
              <div class="right">
                <div class="discount-coupon-name">
                  {{ i.name }}
                </div>

                <div class="period-validity">{{ i.date }}</div>
                <div
                  class="receive-btn"
                  :class="`${item.type === '0' ? '' : 'received'}`"
                  @click="nowOrder(i.id, 0, item.type)"
                  v-if="!i.isGet && !i.noStock"
                >
                  领取
                </div>
                <div
                  v-if="!i.isGet && i.noStock"
                  class="receive-btn no-inventory"
                >
                  已抢光
                </div>
                <div
                  class="receive-btn received"
                  v-if="i.isGet"
                  @click="isOrder(1)"
                >
                  已领取
                </div>
                <div class="maximum-discount">
                  最高优惠{{ i.maxDiscountPrice }}元
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div
      class="money-content"
      v-if="CanGetCoupon.canGetCoupons && CanGetCoupon.canGetCoupons.length > 0"
    >
      <div class="title"></div>
      <div class="noquantity-content">
        <div
          class="limit-content"
          v-for="item in CanGetCoupon.canGetCoupons"
          :key="item.id"
        >
          <div class="noquantity-received-img" v-if="item.isGet">
            <img
              v-if="item.isGet && $route.query.langEnv == 3"
              src="@/assets/image/coupon/Usedzh.png"
              alt=""
            />
            <img
              v-if="item.isGet && $route.query.langEnv != 3"
              src="@/assets/image/coupon/Receive.png"
              alt=""
            />
          </div>
          <!-- <img src="@/assets/image/coupon/money_bg.png" alt="" /> -->
          <div class="limit-left">
            <div class="limit-price">
              <span>HK$</span>
              <div class="price">{{ item.price }}</div>
            </div>
            <div class="max-conditions">
              <div
                v-if="$route.query.langEnv == '3'"
               
              >
                {{ $t("滿") }} {{ item.metPrice }} {{ $t("可用") }}
              </div>
              <div v-else >
                {{ $t("可用") }} {{ $t("滿") }} {{ item.metPrice }}
              </div>
            </div>
          </div>
          <div class="limit-right">
            <div>
              <div class="title">
                <div class="left">
                  <!-- <div class="left-content">
                    {{ item.isAll ? "全场券" : "品类券" }}
                  </div> -->
                  {{ item.name }}
                </div>
              </div>
              <div class="bottom">
                <div class="left">{{ item.date }}</div>
                <div
                  class="right"
                  v-if="!item.isGet"
                  @click="nowOrder(item.id, 1, '0')"
                >
                  {{ $t("領取") }}
                </div>
              </div>
              <div class="question" v-if="item.isAll">
                <div v-if="item.isAll">{{ $t("適用於所有的產品") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Mixins from "@/utils/mixins.js";
import { getFindCanGetCoupon, getTakeCanGetCoupon } from "@/api/coupon.js";
import { getToken } from "@/utils/token.js";
import i18n from "@/i18n";
export default {
  directives: {
    "mouse-drag-scroll": {
      bind(el) {
        let isDragging = false;
        let startX, scrollLeft;

        el.addEventListener("mousedown", e => {
          isDragging = true;
          startX = e.pageX - el.offsetLeft;
          scrollLeft = el.scrollLeft;
          el.style.cursor = "grabbing";
        });

        el.addEventListener("mouseleave", () => {
          isDragging = false;
          el.style.cursor = "grab";
        });

        el.addEventListener("mouseup", () => {
          isDragging = false;
          el.style.cursor = "grab";
        });

        el.addEventListener("mousemove", e => {
          if (!isDragging) return;
          e.preventDefault();
          const x = e.pageX - el.offsetLeft;
          const walk = (x - startX) * 1.5;
          el.scrollLeft = scrollLeft - walk;
        });
      }
    }
  },
  data() {
    return {
      countdownValue: [],
      canGetDisCoupons: [],
      flag1: false,
      flag2: true,
      timer: null,
      CanGetCoupon: {},
      statuCoupon: null
    };
  },
  watch: {
    CanGetCoupon: {
      deep: true,
      handler(val) {
        const countdown = (receiveEndTime, index) => {
          const now = new Date();
          const timeDiff = receiveEndTime - now.getTime();
          if (timeDiff <= 0) {
            this.findCanGetCoupon();
            return;
          }

          const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

          // 格式化字符串，补零
          const formattedDays = days.toString().padStart(2, "0");
          const formattedHours = hours.toString().padStart(2, "0");
          const formattedMinutes = minutes.toString().padStart(2, "0");
          const formattedSeconds = seconds.toString().padStart(2, "0");
          return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;
        };
        val.canGetDisCoupons.forEach((item, index) => {
          this.countdownValue.splice(
            index,
            1,
            countdown(item.receiveEndTime, index)
          );
        });
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          val.canGetDisCoupons.forEach((item, index) => {
            this.countdownValue.splice(
              index,
              1,
              countdown(item.receiveEndTime, index)
            );
          });
        }, 1000);
      }
    }
  },
  mixins: [Mixins],
  created() {
    const langEnv = this.$route.query.langEnv;
    this.handleChangeLang(langEnv, i18n);
    this.findCanGetCoupon();

    document.title = this.$t("領券中心");
  },
  methods: {
    async findCanGetCoupon() {
      const langEnv = this.$route.query.langEnv;
      const res = await getFindCanGetCoupon({}, langEnv);

      res.data.canGetDisCoupons = res.data.canGetDisCoupons.map(item => {
        if (item.type === "1") {
          const dateStr = item.receiveStartTime;
          const dateObj = new Date(dateStr);
          item.month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
          item.day = dateObj
            .getDate()
            .toString()
            .padStart(2, "0");
          item.hours = dateObj
            .getHours()
            .toString()
            .padStart(2, "0");
          item.minutes = dateObj
            .getMinutes()
            .toString()
            .padStart(2, "0");
          item.seconds = dateObj
            .getSeconds()
            .toString()
            .padStart(2, "0");
        }
        return item;
      });
      this.CanGetCoupon = res.data;
      // commit(Types.GET_CAN_COUPONS, res.data);
    },
    async nowOrder(id, flag, type) {
      // this.isLogin();
      const langEnv = this.$route.query.langEnv;
      if (type !== "0") {
        this.$toast("活动未开始，请稍后再来");
        return;
      }
      if (this.isLogin()) {
        const res = await getTakeCanGetCoupon({ id: id }, langEnv);

        await this.findCanGetCoupon();
        if (res.isSuccess) {
          this.statuCoupon = 1;

          // commit(Types.GET_COUS2, data);
          this.$toast(this.$t("領取") + this.$t("成功"));
        }
      }
    },
    nowUse(isAll, id, name) {
      if (this.isLogin()) {
        var _this = this;
        var func1 = function() {
          if (name.indexOf("义齿") > -1) {
            _this.gotoTooth();
            return;
          }
          mmm.immediatelyUse(!isAll ? id : "");
        };
        var func2 = function() {
          if (name.indexOf("义齿") > -1) {
            _this.gotoTooth();
            return;
          }
          window.webkit.messageHandlers.immediatelyUse.postMessage(
            !isAll ? id : ""
          );
        };
        var func3 = function() {
          if (name.indexOf("义齿") > -1) {
            _this.gotoTooth();
            return;
          }
          if (!isAll) {
            _this.$router.push({
              path: "/products",
              query: { useCoupon: 1, couponId: id }
            });
          } else {
            _this.$router.push({ path: "/products", query: { useCoupon: 1 } });
          }
        };
        this.excFunc(func1, func2, func3);
      }
    },
    gotoTooth() {
      var _this = this;
      Request.fetchByAuth2(
        "tooth/sure-auth",
        null,
        res => {
          if (res.resultID === 1340) {
            var func1 = function() {
              mmm.goAuth();
            };
            var func2 = function() {
              window.webkit.messageHandlers.goAuth.postMessage("a");
            };
            var func3 = function() {
              _this.showMsg("您未认证，请先下载梅苗苗APP认证");
            };
            _this.excFunc1(func1, func2, func3);
          } else {
            _this.$router.push({ path: "/serviceNotice" });
          }
        },
        "POST",
        false
      );
    },
    getDay(item) {
      let start = item.date.split("-")[0].replace(/\./g, "-");
      return this.dateMinus(
        new Date(this.dateFtt("yyyy-MM-dd", new Date())),
        new Date(start)
      );
    },
    dateFtt(fmt, date) {
      //author: meizz
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    dateMinus(date1, date2) {
      //date1:小日期   date2:大日期
      var sdate = new Date(date1);
      var now = new Date(date2);
      var days = now.getTime() - sdate.getTime();
      var day = parseInt(days / (1000 * 60 * 60 * 24));
      return day;
    },
    isLogin() {
      var token = getToken();
      if (!token) {
        var _this = this;
        var func1 = function() {
          mmm.toLogin();
        };
        var func2 = function() {
          window.webkit.messageHandlers.toLogin.postMessage("jj");
        };
        var func3 = function() {
          _this.$router.push({
            path: "/login?redirect=" + _this.$route.fullPath
          });
        };

        this.excFunc(func1, func2, func3);
      } else {
        return true;
      }
    }
  }

  // unmounted() {
  //   $("#navbar").css({ background: "#f9f9f9" });
  // },
};
</script>
<style scoped lang="scss">
#navbar {
  background-color: #90d12e;
}
.getCoupons {
  background-color: #90d12e;
  padding: 12px;
  min-height: 100vh;
  max-width: 640px;
  min-width: 320px;
  overflow: hidden;
  margin: 0 auto;
}
.money-content {
  width: 100%;
  margin-bottom: 12px;
  padding-top: 44px;
}
.money-content .title {
  width: 100%;
}
.money-content .title img {
  width: 100%;
}
.money-content .content {
  width: 100%;
  background: #fff;
  padding: 16px 0;
  border-radius: 16px;
}
.money-content .content .top {
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
}
.money-content .content .top .left {
  color: #928df9;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: PingFang SC-Bold, PingFang SC;
}
.money-content .content .top .right-content {
  position: relative;
  color: #88a3ff;
  font-size: 15px;
  margin: 0;
  font-weight: bold;
}
.money-content .content .top .right {
  display: flex;
  align-items: center;
}
.money-content .content .top .right img {
  width: 16px;
  height: 16px;
}
.money-content .content .top .right-content:before {
  content: attr(text);
  position: absolute;
  z-index: 10;
  color: #ff67d6;
  -webkit-mask: linear-gradient(114deg, transparent, #ff67d6);
}
.block-box {
  width: 18px;
  height: 18px;
  background: #928df9;
  color: #fff;
  line-height: 18px;
  font-size: 12px;
  text-align: center;
  margin: 0 2px;
  border-radius: 2px;
  font-weight: 400;
}
.h {
  margin-left: 4px;
}

.money-content .content .bottom {
  list-style-type: none;
}
.money-content .content .bottom .coupons {
  overflow: hidden;
  margin-right: 8px;
  border-radius: 12px 12px 12px 12px;
  background: #ffe9e7;
  position: relative;
}
.coupons-content {
  display: flex;
  align-items: center;
  padding: 0 12px;
  .right {
    display: flex;
  }
}
.first-coupon {
  margin-left: 16px;
}
.last-coupon {
  margin-right: 16px !important;
}
.coupons-title {
  display: flex;
}
.coupons .coupons-num {
  width: 52px;
  height: 22px;
  font-size: 12px;
  color: #e84935;
  background: linear-gradient(270deg, #fac9c4 0%, #fddad6 100%);
  border-radius: 0 0 8px 0;
  text-align: center;
  line-height: 22px;
  visibility: hidden;
}
.coupons .decoration img {
  width: 12px;
  left: 52px;
}
.coupons-content .discount {
  color: #e84935;
  font-size: 22px;
  margin-top: 8px;
  font-weight: 700;
}

.coupons-content .condition {
  color: #e84935;
  font-size: 12px;
}
.discount-coupon-name {
  font-size: 13px;
  width: 102%;
  margin: 7px 0 2px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}
.period-validity {
  font-size: 16px;
  transform: scale(0.625);
  color: #666666;
  width: 150%;
  margin-left: 12px;
  margin-top: -3px;
}
.receive-btn {
  background: linear-gradient(270deg, #e84935 0%, rgba(232, 73, 53, 0.6) 100%);
  border-radius: 16px 16px 16px 16px;
  text-align: center;
  color: #ffffff;
  padding: 2px 8px;
  line-height: 1.5;
}
.maximum-discount {
  font-size: 16px;
  transform: scale(0.625);
  color: #ef7b6c;
}
.received {
  background: linear-gradient(270deg, #e84935 0%, rgba(232, 73, 53, 0.6) 100%)
    rgba(255, 255, 255, 0.6) !important;
  opacity: 0.4;
}
.received-img img {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
}
.noquantity-content {
  padding: 16px;
  padding-top: 32px;
  border-radius: 16px;
  background-color: #fff;
}
.noquantity-content .limit-content {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 92px;
  position: relative;
  background: url("~@/assets/image/coupon/mycoupon.png") no-repeat;
  background-size: 115% 100%;
  border-radius: 12px;
}
.noquantity-content .limit-content img {
  width: 100%;
}
.limit-left {
  padding-left: 12px;
  width: 35%;
}
.limit-left .limit-price {
  font-size: 18px;
  color: #e84935;
  font-weight: 700;
  display: flex;
  align-items: end;
  line-height: 1;
  margin-bottom: 4px;
  span {
    font-size: 12px;
  }
  .price {
    font-size: 18px;
    span {
      margin-left: 2px;
      font-size: 12px;
    }
  }
}
.limit-left .max-conditions {
  color: #e84935;
  font-size: 12px;
}
.limit-right {
  padding: 12px;
  flex: 1;
  font-size: 2vh;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}
.limit-right .title {
  overflow: hidden;
  min-height: 40px;
}
.limit-right .bottom {
  display: flex;
  align-items: center;
  font-weight: 400;
  bottom: 0.6vh;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
}

.limit-right .title .left-content {
  font-size: 1.6vh;
  height: 2.4vh;
  line-height: 2.4vh;
  width: 6.1vh;
  text-align: center;
  border-radius: 0.6vh 0.6vh 0.6vh 0;
  font-weight: 400;
  margin-right: 0.6vh;
  background: #e84935;
  color: #fff;
  margin-top: 0.6vh;
  margin: 0;
  display: inline-block;
}

.limit-content {
  position: relative;
  height: 100%;
  margin-bottom: 16px;
}
.noquantity-received-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 8.4vh;
  height: 8.4vh;
}
.noquantity-received-img img {
  width: 100%;
}
.limit-right .bottom .left {
  font-size: 10px;
  color: #666666;
  flex: 1;
}
.limit-right .bottom .right {
  cursor: pointer;
  font-size: 2vh;
  color: #fff;
  background: linear-gradient(270deg, #e84935 0%, rgba(232, 73, 53, 0.6) 100%);
  width: 6.3vh;
  font-size: 12px;
  border-radius: 2.4vh;
  text-align: center;
  padding: 2px 8px;
}
.no-inventory {
  background: linear-gradient(270deg, #bdbdbd 0%, #e0d3d3 100%) !important;
}

.maxDiscountPrice {
  font-weight: 500;
  font-size: 10px;
  color: #ef7b6c;
}
.question {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #999999;
  font-weight: normal;
}
</style>
